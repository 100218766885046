<template>
    <Toast />
    <div class="flex flex-col min-h-screen h-full">
      <div class="flex-grow flex flex-col items-center justify-center">
        <router-view class="flex flex-col justify-center items-center h-full" />
      </div>
      <div class="flex justify-center w-full p-3 text-lg text-surface-300">
        &copy;{{ new Date().getFullYear() }} &bull; Invictus. All rights reserved.
      </div>
    </div>
  </template>

<script setup>
import { provide} from 'vue'
import { useToast } from 'primevue/usetoast';
import Toast from 'primevue/toast';

const toast = useToast();

const showToast = (severity, summary, detail) => {
    let msg = { severity: severity, summary: summary, detail: detail, life: 3000 };
    toast.add(msg);
};


provide('showToast', showToast);

</script>