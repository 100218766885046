<template>
    <div class="m-0 flex flex-col" :class="{ 'items-end': !normal, 'items-stretch': normal }">
        <DescLabel v-if="label!=null" :label="label" />
        <component :is="componentsMap[component]" ref="input" :class="{ 'lg:justify-end': !normal}" v-bind="innerInputProps" :pt="autocompleteSize"
            :options="options" :suggestions="filteredData" :placeholder="placeholder" :invalid="state" @click="handleClick"
            @update:modelValue="handleInput" @complete="search" v-model="data" class="w-full justify-center lg:justify-start"/>
        <ErrorLabel v-if="state" :labels="errorLabels" :index="errorIndex" />
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, defineExpose, nextTick, onMounted } from 'vue';
import DescLabel from './DescLabel.vue';
import ErrorLabel from './ErrorLabel.vue';
import InputText from 'primevue/inputtext';
import SelectButton from 'primevue/selectbutton';
import InputNumber from 'primevue/inputnumber'; 
import Select from 'primevue/select';
import DatePicker from 'primevue/datepicker';
import AutoComplete from 'primevue/autocomplete';
import TextArea from 'primevue/textarea';
import Password from "primevue/password";
const TextInputNumber = InputText;

const componentsMap = {
    InputText,
    SelectButton,
    InputNumber,
    Select,
    DatePicker,
    AutoComplete,
    TextInputNumber,
    TextArea,
    Password
};

// not working in current version 
// moved to onMounted function and done manually 
// needs fix later
const autocompleteSize = ref({
    pcinput: {
        'style': { 'background-color': 'green', 'color': 'green' }
    }
});

const debounceTimers = ref({});
const input = ref();
const errorIndex = ref(0);
const state = ref(false);
const data = ref();
const filteredData = ref([]);

const props = defineProps({
    modelValue: {
        type: [String, Number, Date]
    },
    label: String,
    component: String,
    placeholder: {
        type: String,
        default: () => ""
    },
    errorLabels: {
        type: Array,
        default: () => []
    },
    conditions: {
        type: Array,
        default: () => []
    },
    options: {
        type: Array,
        default: () => []
    },
    suggestions: {
        type: Array,
        default: () => []
    },
    innerInputProps: {
        type: Object,
        default: () => { }
    },
    normal: {
        type: Boolean,
        default: true
    }
})

const emit = defineEmits(['update:modelValue']);

data.value = props.modelValue;

watch(() => props.modelValue, (newVal) => {
    data.value = newVal;
});

onMounted(() => {
    if (props.component === "AutoComplete" || props.component === "Password") {
        input.value.$el.querySelector('div input').setAttribute('style', 'width: 100%;');
    }
    else if (props.component === "DatePicker") {
        input.value.$el.querySelector('span input').setAttribute('style', 'width: 100%;');
        if(data.value !== null && data.value !== undefined && data.value !== "") 
            data.value = new Date(data.value);
    }
    else if (props.component === "InputNumber") {
        input.value.$el.querySelector('span input').setAttribute('style', 'width: 100%;');
    }
});

const focus = () => {
    let inputElement;

    if (props.component === "InputText" || props.component === "TextInputNumber" || props.component ==="Password") inputElement = input.value.$el;
    if (props.component === "InputNumber" || props.component === "DatePicker") inputElement = input.value.$el.querySelector('span input');
    if (props.component === "SelectButton") inputElement = input.value.$el.querySelector('div');
    if (props.component === "Select") inputElement = input.value.$el.querySelector('div span');
    if (props.component === "AutoComplete") inputElement = input.value.$el.querySelector('div input');

    nextTick(() => {
        if (inputElement) {
            inputElement.focus();
        }
    })
}


const search = (event) => {
    if (!event.query.trim().length) {
        filteredData.value = [...props.suggestions];
    } else {
        filteredData.value = props.suggestions.filter((suggestion) => {
            return suggestion.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }
}

const handleClick = () => {
    // console.log("🚀 ~ handleClick ~  input.value:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m",  input.value)
    if (props.component === "Select") input.value.show();
    
}

const handleInput = () => {
    clearTimeout(debounceTimers.value);

    debounceTimers.value = setTimeout(() => {
        validate();
        emit('update:modelValue', data.value);
    }, 10);
}

const validate = () => {
    // console.log("🚀 ~ validate ~ confm:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", confm)
    // console.log("🚀 ~ validate ~ input.value:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", input.value)
    // if(confm)input.value.forceUpdate();
    if (props.component === "InputText" || props.component === "AutoComplete" || props.component === "TextArea" || props.component === "Password") state.value = !validateText();
    else if (props.component === "TextInputNumber") {
        data.value = isNaN(parseInt(data.value)) ? '' : parseInt(data.value);
        state.value = !validateNumber();
    }
    else if (props.component === "InputNumber") state.value = !validateNumber();
    else if (props.component === "SelectButton" || props.component === "Select") state.value = !validateSelect();
    else if (props.component === "DatePicker") state.value = !validateDate();
    return !state.value;
}




const validateText = () => {
    const value = data.value ? data.value : "";
    for (let condInd = 0; condInd < props.conditions.length; condInd++) {
        let cond = props.conditions[condInd];
        errorIndex.value = condInd;

        if (cond.type === "required" && value.length == 0) {
            return false;
        } else if (cond.type === "length" && (value.length < cond.minvalue || value.length > cond.maxvalue)) {
            return false;
        } else if (cond.type === "regex" && !(new RegExp(cond.value)).test(value)) {
            return false;
        } else if (cond.type === "value" && value !== props.options[cond.option]) { 
            return false;
        } else if (cond.type === "pesel") {
            if (typeof value !== 'string') return false;
            if (!new RegExp('^[0-9]{2}([02468][1-9]|[13579][0-2])(0[1-9]|[1-2][0-9]|3[01])[0-9]{5}$').test(value)) return false;

            let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
            let sum = 0;
            let controlNumber = parseInt(value.substring(10, 11));

            for (let i = 0; i < weight.length; i++) {
                sum += (parseInt(value.substring(i, i + 1)) * weight[i]);
            }
            sum = sum % 10;
            return (10 - sum) % 10 === controlNumber;
        }
    }
    return true;
}

const validateNumber = () => {
    const value = data.value;
    for (let condInd = 0; condInd < props.conditions.length; condInd++) {
        let cond = props.conditions[condInd];
        errorIndex.value = condInd;

        if (cond.type === "required" && (!value && value!=0 || isNaN(value))) {
            return false;
        } else if (cond.type === "value" && (value < cond.minvalue || value > cond.maxvalue)) {
            return false;
        } else if (cond.type === "regex" && !(new RegExp(cond.value)).test(value)) {
            return false;
        }
    }
    return true;
}

const validateSelect = () => {
    const value = data.value;
    for (let condInd = 0; condInd < props.conditions.length; condInd++) {
        let cond = props.conditions[condInd];
        errorIndex.value = condInd;

        if (cond.type === "required" && !value && !props.options.includes(value)) {
            return false;
        } else if (cond.type === "value" && value !== props.options[cond.option]) {
            return false;
        } else if (cond.type === "length" && (value.length < cond.minvalue || value.length > cond.maxvalue)) {
            return false;
        } else if (cond.type === "regex" && !(new RegExp(cond.value)).test(value)) {
            return false;
        }
    }
    return true;
}

const validateDate = () => {
    const value = data.value;
    for (let condInd = 0; condInd < props.conditions.length; condInd++) {
        let cond = props.conditions[condInd];
        errorIndex.value = condInd;

        if (cond.type === "required" && !value) {
            return false;
        } 
    }
    return true;
}
defineExpose({
    focus,
    validate
});

</script>
