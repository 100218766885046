<template>
    <main class="mt-0 pl-3 pr-2 pb-3 flex justify-center items-start gap-3">
        <div style="position: relative;" class="flex justify-center items-start"
            :class="{ 'w-full': instructionActive != true, 'w-9/12': instructionActive == true }">
            <BlockUI :blocked="block" :pt="mainFormBlur"
                class="shadow rounded-lg p-2 h-max w-11/12 bg-white dark:bg-slate-900" style="z-index: 1;">
                <div class="flex justify-between items-center mx-3 mt-3">
                    <h1 class="text-xl font-bold">{{ formName }}</h1>
                    <Button label="Zapisz" icon="pi pi-save" class="mr-[3rem]" raised outlined @click="saveForm" />
                </div>
                <Divider />
                <Tabs v-model:value="value" class="px-3 pb-3">
                    <div class="flex flex-row relative w-full">
                        <Button outlined label="<" @click="prevbtn()" class="border-b-4 rounded-r-[0px]" />
                        <TabList class="overflow-hidden w-full"
                            :pt="{ tablist: { class: 'flex flex-row border-primary border-b-4 overflow-x-hidden' } }">
                            <Tab class="min-w-fit p-3" v-for="tab in formData" :key="tab.name" :id="tab.value"
                                :value="tab.value" @click="scrollToItem(tab.value)"
                                :class="{ 'text-white bg-primary rounded-t-[5px]': tab.value === value }">{{ tab.name }}
                            </Tab>
                        </TabList>
                        <Button outlined label=">" @click="nextbtn()" class="border-b-4 rounded-l-[0px]" />
                    </div>


                    <TabPanels>
                        <TabPanel v-for="(tab, tabIndex) in formData" :key="tab.name" :value="tab.value">
                            <div v-if="tab.component === 'SubObject'">
                                <DataTable v-model:selection="activeObject" :value="subObjectValues[tab.subObjectIndex]"
                                    dataKey="uuid" tableStyle="min-width: 50rem" size="small" class="text-base pt-3">
                                    <template #empty> Brak składników majątku. </template>
                                    <template #header>
                                        <Button @click="addSubObject(tab.subObjectIndex)" icon="pi pi-plus" class="mr-2"
                                            :severity="tab.severityStateAdd" outlined rounded raised />
                                        <Button @click="editSubObject(tab.subObjectIndex)" icon="pi pi-pencil"
                                            class="mr-2" :severity="tab.severityStateEdit" outlined rounded raised />
                                        <Button @click="deleteSubObject(tab.subObjectIndex)" icon="pi pi-trash"
                                            class="mr-2" :severity="tab.severityStateDel" outlined rounded raised />
                                    </template>
                                    <Column selectionMode="single" headerStyle="width: 3rem"></Column>
                                    <Column v-for="(item, index) in tab.columns" :field="item.name"
                                        :header="item.colLabel">
                                        <template v-if="item.name.endsWith('date')" #body="slotProps">
                                            {{ formatDate(slotProps.data[item.name]) }}
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                            <div v-else class="flex flex-col  items-center p-3">
                                <div v-for="(item, index) in tab.content" class="w-8/12">
                                    <div v-if="item.component === 'SelectStatus'" class="flex flex-col">
                                        <DescLabel :label="item.label" />
                                        <Select :ref="references[item.uuid]" v-model="formValues[tabIndex][index].value"
                                            :options="item.options">
                                            <template #value="slotProps">
                                                <StatusTag :status="slotProps.value"
                                                    :severityMap="severityMap" :msgMap="msgMap" />
                                            </template>
                                            <template #option="slotProps">
                                                <StatusTag :status="slotProps.option"
                                                    :severityMap="severityMap" :msgMap="msgMap" />
                                            </template>
                                        </Select>
                                    </div>

                                    <FormField v-else :ref="references[item.uuid]" v-bind="item"
                                        v-model="formValues[tabIndex][index].value" />
                                </div>
                            </div>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </BlockUI>
            <div style="position: absolute;z-index: 2;" class="top-8 w-10/12 xl:w-9/12" v-if="visible">
                <div v-for="(item, itemIndex) in subObjects" class="w-full">
                    <Panel class="flex justify-center flex-col mb-5 shadow-md" style="border-radius: 0.5rem;"
                        v-if="activeSubObject == item.subObjectIndex" :key="itemIndex">
                        <template #header>
                            <span>{{ item.subName }}</span>
                        </template>
                        <template #icons>
                            <button class="p-panel-header-icon p-link mr-2" @click="cancelSubObject">
                                <span class="pi pi-times"></span>
                            </button>
                        </template>
                        <div class="flex flex-col  items-center">
                            <FormField v-for="(input, propIndex) in item.content" :ref="references[input.uuid]"
                                v-bind="input" v-model="activeObject[input.name]" class="w-8/12" />
                        </div>
                        <Button label="Zapisz" @click="saveSubObject(item.subObjectIndex)" class="mt-5" />
                    </Panel>
                </div>
            </div>
        </div>

        <Card v-if="instructionActive" style="width: 25%; overflow: hidden;position: sticky;"
            class="h-max min-w-64 sticky top-0 max-w-96">
            <template #header>
                <!-- <img alt="user header" src="/images/usercard.png" /> -->
            </template>
            <template #title>Instrukcja wypełniania wniosku</template>
            <template #subtitle>
                <PanelMenu :model="instructionData" class="w-full md:w-[20rem]" />
            </template>
            <template #content>
                <p class="m-0">{{ instructionText }}</p>
                <!-- Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error
                    repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam

                </p> -->
            </template>
            <template #footer>
                <div class="flex gap-3 mt-1">
                    <Button label="Cofnij" severity="secondary" outlined class="w-full" />
                    <Button label="Dalej" class="p-0 w-full" />
                </div>
            </template>
        </Card>
    </main>
</template>

<script setup>
import { ref, inject, watch, onMounted } from 'vue'
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { useRoute, useRouter } from 'vue-router'

import Card from 'primevue/card';
import BlockUI from 'primevue/blockui';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';

import Divider from 'primevue/divider';
import Select from 'primevue/select';
import Tag from 'primevue/tag';
import Button from 'primevue/button';


import FormField from '@/components/forms/FormField.vue'
import DescLabel from '@/components/forms/DescLabel.vue';
import StatusTag from '@/components/forms/StatusTag.vue';

let instructionActive = ref(false);
const value = ref("0");
const uuid = ref();

const route = useRoute();
const router = useRouter();
const visible = ref(false);
const block = ref(false);

const formValues = ref();
let tabItemId = 0;

const severityMap = new Map([
    ['no_access', 'danger'],
    ['not_matched', 'danger'],
    ['verification_needed', 'warn'],
    ['transaction_in_progress', 'warn'],
    ['requires_completion', 'secondary'],
    ['never_matched', 'secondary'],
    ['ready', 'success'],
    ['matched', 'success'],
]);

const msgMap = new Map([
    ['ready', 'Zweryfikowany'],
    ['verification_needed', 'Wymaga weryfikacji'],
    ['requires_completion', 'Wymaga uzupełnienia'],
    ['never_matched', 'Nie wyszukiwano'],
    ['not_matched', 'Nie dopasowano'],
    ['matched', 'Dopasowano'],
    ['transaction_in_progress', 'Oczekiwanie na zakończenie płatności'],
    ['no_access', 'Brak dostępu']
]);

const subObjectValues = ref(Array(12).reduce((acc, item, itemIndex) => {
    acc[itemIndex] = [];
    return acc;
}, {}));

const mainFormBlur = ref({
    mask: {
        style: { 'border-radius': '8px' }
    }
});
const activeSubObject = ref(0);

const setMainFormStyle = () => {
    block.value = !block.value;
    if (!block.value) mainFormBlur.value.root = { style: { 'filter': 'blur(0px)' } };
    else mainFormBlur.value.root = { style: { 'filter': 'blur(2px)' } };
}

const showSubObject = () => {
    visible.value = !visible.value;
    setMainFormStyle();
}
const activeObject = ref();
let tempUUID = 0;

const formatDate = (date) => {
    if (date == null) return null;
    return format(new Date(date), 'dd/MM/yyyy');
};

const customInput = ref(null)
const references = Array.from({ length: 300 }, (_, i) => ({}[i + ""] = ref()));
const instructionText = ref('');

function scrollToItem(itemId) {
    const item = document.getElementById(itemId);
    item.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

function nextbtn() {
    let val = parseInt(value.value) + 1;
    if (val < formData.value.length) {
        scrollToItem(val + "");
        value.value = val + "";
    }
}

function prevbtn() {
    let val = parseInt(value.value) - 1;
    if (val >= 0) {
        scrollToItem(val + "");
        value.value = val + "";
    }
}


// const references = ref(Array(24).fill(null).map((_, index) => ref(null)));
function focusCustomInput(uuid) {
    // Calling focus on a ref will target the first element in the ref's v-for loop
    // console.log("🚀 ~ focusCustomInput ~ customInput.value:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", customInput.value[0].value)
    // console.log("🚀 ~ focusCustomInput ~ customInput.value.$el:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", references[uuid].value[0])
    // console.log("🚀 ~ focusCustomInput ~ customInput.value.$el:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", references.value)
    // const focus = inject('focus');
    // console.log("🚀 ~ focusCustomInput ~ focus:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", focus)
    // focus.focus();
    // if (customInput.value[0] == null) {
    //     customInput.value[0].focus();
    // }
    references[uuid].value[0].focus();
}

const getDateMinus = (minus) => {
    minus = minus % 12;
    const date = new Date();
    date.setMonth(date.getMonth() - minus);

    if (date.getMonth() <= 0) {
        date.setFullYear(date.getFullYear() - 1);
        date.setMonth(date.getMonth() + 12);
    }

    let data = format(date, 'MMMM yyyy', { locale: pl })

    data = data.replace('stycznia', 'styczeń');
    data = data.replace('lutego', 'luty');
    data = data.replace('marca', 'marzec');
    data = data.replace('kwietnia', 'kwiecień');
    data = data.replace('maja', 'maj');
    data = data.replace('czerwca', 'czerwiec');
    data = data.replace('lipca', 'lipiec');
    data = data.replace('sierpnia', 'sierpień');
    data = data.replace('września', 'wrzesień');
    data = data.replace('pazdziernika', 'październik');
    data = data.replace('listopada', 'listopad');
    data = data.replace('grudnia', 'grudzień');

    return data;
}

let loadedData = {};

onMounted(() => {
    uuid.value = route.params.id;

    fetch("/api/getObject", {
        body: JSON.stringify({
            objectUUID: uuid.value,
            functionalityUUID: "c98b6831-bc1a-4d84-b78a-fbb1188ca440"
        })
    }).then(response => response.json()).then(data => {
        if (data.success == 1) {
            loadedData = data.result.root.properties;

            let formValData = data.result.root.properties.data != "" ? JSON.parse(data.result.root.properties.data) : {
                active: 0,
                previousDiff: true,
                instructionActive: false,
                sideInstructionActive: false,
                sideActive: [0],
                lastJumpId: 0,
                jumped: false,
                jumpedToEnd: false,
                lastSectionEndId: 0,
                disableNextBtn: false,
                countries: ['Polska'],
                cities: [],
                streets: [],
                houseNumbers: [],
                flatNumbers: [],
                postalCodes: [],
                formVal: Array(300).fill(null),
                optionMatrix: []
            };

            loadedData.data = formValData;

            // if (formValData.formVal[KRDid] === null) {
            //     formValData.formVal[KRDid] = 'Tak';
            //     formValData.formVal[KRDid + 1] = 'Tak';
            //     formValData.formVal[KRDid + 2] = 'Tak';
            // }

            // active.value = formValData.active;
            // previousDiff = formValData.previousDiff;
            // instructionActive.value = formValData.instructionActive;
            // sideInstructionActive.value = formValData.sideInstructionActive;
            // sideActive.value = formValData.sideActive;
            // lastJumpId.value = formValData.lastJumpId;
            // jumped.value = formValData.jumped;
            // jumpedToEnd.value = formValData.jumpedToEnd;
            // lastSectionEndId.value = formValData.lastSectionEndId;
            // disableNextBtn.value = formValData.disableNextBtn;
            // countries.value = formValData.countries;
            // cities.value = formValData.cities;
            // streets.value = formValData.streets;
            // houseNumbers.value = formValData.houseNumbers;
            // flatNumbers.value = formValData.flatNumbers;
            // postalCodes.value = formValData.postalCodes;
            // formValues.value = formValData.formVal;

            //dane osobowe
            for (let i = 0; i < 18; i++) {
                formValues.value[2][i].value = formValData.formVal[i + 1];
            }

            //uzasadnienie
            formValues.value[0][0].value = data.result.root.properties.description;
            for (let i = 0; i < 8; i++) {
                formValues.value[0][i + 1].value = formValData.formVal[i + 262];
            }
            for (let i = 0; i < 3; i++) {
                formValues.value[0][i + 9].value = formValData.formVal[i + 275];
            }
            formValues.value[0][12].value = formValData.formVal[279];
            formValues.value[0][13].value = formValData.formVal[281];
            formValues.value[0][14].value = formValData.formVal[282];

            //statusy i dane sądu
            formValues.value[1][0].value = data.result.root.properties.status;
            formValues.value[1][1].value = data.result.root.properties.statusCourt;
            if (data.result.root.properties.statusCourt == 'matched') {
                formValues.value[1][2].value = formValData.court.name;
                formValues.value[1][3].value = formValData.court.city;
                formValues.value[1][4].value = formValData.court.street;
                formValues.value[1][5].value = formValData.court.building;
                formValues.value[1][6].value = formValData.court.locale;
                formValues.value[1][7].value = formValData.court.postalCode;
            }


            console.log("🚀 ~ onMounted ~ formValues.value:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", formValues.value)

            console.log("🚀 ~ onMounted ~ formValData:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", formValData.formVal)

            subObjectValues.value = data.result.root.properties.objects != "" ? JSON.parse(data.result.root.properties.objects) : (subObjects.value.reduce((acc, item, itemIndex) => {
                acc[itemIndex] = [];
                return acc;
            }, {}));

            for (const element in subObjectValues.value) {
                subObjectValues.value[element].forEach((subItem, subItemIndex) => {
                    subItem.uuid = tabItemId++;

                    let generalKey = Object.keys(subItem).find(key => key.endsWith('_city'))?.replace('_city', '');

                    if (generalKey !== undefined) {
                        subItem[generalKey + '_address'] =
                            subItem[Object.keys(subItem).find(key => key.endsWith('_street'))] + " " +
                            subItem[Object.keys(subItem).find(key => key.endsWith('_house_number'))] +
                            (subItem[Object.keys(subItem).find(key => key.endsWith('_flat_number'))] === undefined ? "" : "/" + subItem[Object.keys(subItem).find(key => key.endsWith('_flat_number'))]) + ", " +
                            subItem[Object.keys(subItem).find(key => key.endsWith('_postal_code'))] + " " +
                            subItem[Object.keys(subItem).find(key => key.endsWith('_city'))] + ", " +
                            subItem[Object.keys(subItem).find(key => key.endsWith('_country'))];
                    }

                })
            }

            console.log("🚀 ~ subObjectValues.value.forEach ~ subObjectValues:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", subObjectValues)


            // inspectors.value = subObjectValues.value[3];
            // windycation_companies.value = subObjectValues.value[2];

            // let i = 0;
            // formData.value.filter(obj => obj.component === 'SubObject' || obj.component === 'MultiLoop').forEach(obj => {
            //     obj.optionid = formValData?.optionMatrix[i] !== undefined ? formValData.optionMatrix[i++] : 0;

            // })

            // loading.value = false;
        } else {
            // console.log("🚀 ~ data ~ data:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", data)
        }
    })

});

const showToast = inject('showToast');

//
const instructionData = ref([
    {
        label: 'Spis treści',
        items: [
            {
                label: 'Dane Osobowe',
                items: [
                    {
                        label: 'Imie i Nazwisko',
                        command: () => {
                            focusCustomInput("1");
                        }
                    }, {
                        label: 'Wiek',
                        command: () => {
                            focusCustomInput("2");
                            instructionText.value = `Jedno z pól z informacjami ogólnymi o tobie. Podaj swój wiek. `;
                        }
                    }, {
                        label: 'PESEL',
                    }
                ]
            }, {
                label: 'Majątek',
                command: () => {
                    // focusCustomInput("8");
                    instructionText.value = `Twój majątek - przedmioty, akcje , itp. , które reprezentują główną część twojego majątku.`;
                },
                items: [
                    {
                        label: 'Opis składnika majątku',

                    }, {
                        label: 'Czy ustanowiono zabezpieczenie na majątku?',
                    }, {
                        label: 'Data ustanowienia zabezpieczenia',
                        command: () => {
                            focusCustomInput("8");
                            instructionText.value = `To pole dotyczy daty ustanowienia zabezpieczenia. Np. Jeśli wykupiłeś ubezpieczenie na samochód w dniu 21.01.2020, to tutaj podaj tą datę.`;
                        }
                    }, {
                        label: 'Przybliżona wycena majątku',
                    }, {
                        label: 'Miejsce przebywania majątku',
                    }
                ]
            }
        ]
    }
]);

const formName = ref('Wniosek o upadłość konsumencką')
const formData = ref([
    {
        name: 'Uzasadnienie wniosku',
        component: "Form",
        value: "0",
        content: [
            {
                uuid: "0",
                label: "Tekst uzasadnienia:",
                name: 'description',
                component: "TextArea",
                innerInputProps: {
                    rows: 5,
                    cols: 50,
                    autoResize: true
                },
            }, {
                uuid: "1",
                label: 'Czy chciałeś/łaś podjąć dodatkową pracę lub wyjechać za granicę w celach zarobkowych, w celu spłaty długu?',
                component: "SelectButton",
                options: ['Tak', 'Nie'],
            }, {
                uuid: "2",
                label: 'Czy rodzina lub znajomi pomagali bądź pomagają Ci się utrzymać?',
                component: "SelectButton",
                options: ['Tak', 'Nie'],
            }, {
                uuid: "3",
                label: 'Czy Twoje zobowiązania powstały w wyniku zaciągniętych osobiście przez Ciebie lub z współkredytobiorcą kredytów/pożyczek itp.?',
                component: "SelectButton",
                options: ['Tak', 'Nie'],
            }, {
                uuid: "4",
                label: "Opisz na co przeznaczyłeś środki z pierwszych zaciągniętych zobowiązań.",
                name: 'description_part1',
                component: "TextArea",
                innerInputProps: {
                    rows: 5,
                    cols: 50,
                    autoResize: true
                },
            }, {
                uuid: "5",
                label: "Jeśli posiadasz zobowiązania inne niż zaciągane osobiście to opisz je krótko w 2-3 zdaniach.",
                name: 'description_part1',
                component: "TextArea",
                innerInputProps: {
                    rows: 5,
                    cols: 50,
                    autoResize: true
                },
            }, {
                uuid: "6",
                label: "Kiedy i dlaczego przestałeś/łaś prawidłowo regulować swoje zobowiązania?",
                name: 'description_part2',
                component: "TextArea",
                innerInputProps: {
                    rows: 5,
                    cols: 50,
                    autoResize: true
                },
            }, {
                uuid: "7",
                label: "Opisz swoją sytuację rodzinną w 2-3 zdaniach.",
                name: 'description_part3',
                component: "TextArea",
                innerInputProps: {
                    rows: 5,
                    cols: 50,
                    autoResize: true
                },
            }, {
                uuid: "8",
                label: "Opisz swoją sytuację zdrowotną w 2-3 zdaniach.",
                name: 'description_part3',
                component: "TextArea",
                innerInputProps: {
                    rows: 5,
                    cols: 50,
                    autoResize: true
                },
            }, {
                uuid: "9",
                label: "Opisz swoją sytuację zawodową w 2-3 zdaniach.",
                name: 'description_part3',
                component: "TextArea",
                innerInputProps: {
                    rows: 5,
                    cols: 50,
                    autoResize: true
                },
            }, {
                uuid: "10",
                label: 'Czy kiedykolwiek prowadziłeś/łaś działalność gospodarczą?',
                component: "SelectButton",
                options: ['Tak', 'Nie'],
            }, {
                uuid: "11",
                label: 'Czy Twoja działalność gospodarcza jest zamknięta i wykreślona z rejestru?',
                component: "SelectButton",
                options: ['Tak', 'Nie'],
            }, {
                uuid: "12",
                label: 'Czy posiadasz dokument potwierdzający zamknięcie działalności i wykreślenie z rejestru?',
                component: "SelectButton",
                options: ['Tak', 'Nie'],
            }, {
                uuid: "13",
                label: 'Nazwa dokumentu',
                name: 'document_name',
                component: "InputText",
            }, {
                uuid: "14",
                label: 'Data wystawienia dokumentu',
                name: 'document_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
            }
        ]
    }, {
        name: 'Statusy i adres sądu',
        component: "Form",
        value: "1",
        content: [
            {
                uuid: "15",
                label: "Status wniosku",
                name: 'status',
                component: "SelectStatus",
                options: ['ready', 'verification_needed', 'transaction_in_progress', 'requires_completion', 'no_access'],

            }, {
                uuid: "16",
                label: "Status sądu",
                name: 'statusCourt',
                component: "SelectStatus",
                options: ['never_matched', 'not_matched', 'matched'],

            }, {
                uuid: "17",
                label: 'Nazwa sądu:',
                component: "InputText",
            }, {
                uuid: "18",
                label: 'Miejscowość sądu:',
                component: "InputText",
            }, {
                uuid: "19",
                label: 'Ulica sądu:',
                component: "InputText",
            }, {
                uuid: "20",
                label: 'Numer budynku sądu:',
                component: "InputText",
            }, {
                uuid: "21",
                label: 'Numer lokalu sądu:',
                component: "InputText",
            }, {
                uuid: "22",
                label: 'Kod pocztowy sądu:',
                component: "InputText",
            }
        ]
    }, {
        name: 'Dane osobowe',
        component: "Form",
        value: "2",
        content: [
            {
                uuid: "23",
                label: 'Imie',
                component: "InputText",
            }, {
                uuid: "24",
                label: 'Nazwisko',
                component: "InputText",
            }, {
                uuid: "25",
                label: 'PESEL:',
                component: "InputText",
            }, {
                uuid: "26",
                label: 'Email:',
                component: "InputText",
            }, {
                uuid: "27",
                label: 'Telefon:',
                component: "InputText",
            }, {
                uuid: "28",
                label: 'Miejscowość zamieszkania:',
                component: "InputText",
            }, {
                uuid: "29",
                label: 'Ulica:',
                component: "InputText",
            }, {
                uuid: "30",
                label: 'Numer domu:',
                component: "InputText",
            }, {
                uuid: "31",
                label: 'Numer mieszkania:',
                component: "InputText",
            }, {
                uuid: "32",
                label: 'Kod pocztowy:',
                component: "InputText",
            }, {
                uuid: "33",
                label: 'Czy twój adres do doręczenia jest taki sam jak adres zamieszkania?',
                component: "SelectButton",
                options: ['Tak', 'Nie'],
            }, {
                uuid: "34",
                label: 'Kraj doręczenia:',
                component: "InputText",
            }, {
                uuid: "35",
                label: 'Miejscowość doręczenia:',
                component: "InputText",
            }, {
                uuid: "36",
                label: 'Ulica doręczenia:',
                component: "InputText",
            }, {
                uuid: "37",
                label: 'Numer domu doręczenia:',
                component: "InputText",
            }, {
                uuid: "38",
                label: 'Numer mieszkania doręczenia:',
                component: "InputText",
            }, {
                uuid: "39",
                label: 'Numer skrytki pocztowej:',
                component: "InputText",
            }, {
                uuid: "40",
                label: 'Kod pocztowy doręczenia:',
                component: "InputText",
            },
        ]
    }, {
        name: 'Majątek',
        subName: 'Składnik majątku',
        component: "SubObject",
        value: "3",
        subObjectIndex: 0,
        severityStateAdd: 'secondary',
        severityStateEdit: 'secondary',
        severityStateDel: 'secondary',
        content: [
            {
                uuid: "41",
                label: 'Typ majątku',
                name: 'asset_type',
                options: ["Nieruchomość", "Samochód", "Prawa spadkowe", "Motocykl", "Motorower", "Skuter", "Dzieła sztuki", "Udziały w spółkach", "Akcje", "Jednostki funduszy inwestycyjnych", "Inne"],
                component: "Select",
            }, {
                uuid: "42",
                label: 'Opis składnika majątku',
                name: 'asset_description',
                component: "InputText",
            }, {
                uuid: "43",
                label: 'Czy ustanowiono zabezpieczenie na majątku?',
                name: 'asset_secured',
                component: "SelectButton",
                options: ['Tak', 'Nie'],
            }, {
                uuid: "44",
                label: 'Data ustanowienia zabezpieczenia',
                name: 'asset_securing_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                },
            }, {
                uuid: "45",
                label: 'Waluta wyceny',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
            }, {
                uuid: "46",
                label: 'Przybliżona wycena majątku',
                name: 'asset_price',
                component: "InputNumber",
            }, {
                uuid: "47",
                label: 'Kraj',
                name: 'asset_country',
                component: "InputText",
            }, {
                uuid: "48",
                label: 'Miasto',
                name: 'asset_city',
                component: "InputText",
            }, {
                uuid: "49",
                label: 'Ulica',
                name: 'asset_street',
                component: "InputText",
            }, {
                uuid: "50",
                label: 'Numer domu',
                name: 'asset_house_number',
                component: "InputText",
            }, {
                uuid: "51",
                label: 'Numer mieszkania',
                name: 'asset_flat_number',
                component: "InputNumber",
            }, {
                uuid: "52",
                label: 'Kod pocztowy',
                name: 'asset_postal_code',
                component: "InputText",
            }
        ]
    }, {
        name: 'Należności',
        subName: 'Składnik Należności',
        component: "SubObject",
        value: "4",
        subObjectIndex: 1,
        severityStateAdd: 'secondary',
        severityStateEdit: 'secondary',
        severityStateDel: 'secondary',
        content: [
            {
                uuid: "53",
                label: 'Imię i nazwisko/nazwa podmiotu:',
                name: 'entity_name',
                component: "InputText",
            }, {
                uuid: "54",
                label: 'Kraj podmiotu',
                name: 'entity_country',
                component: "InputText",
            }, {
                uuid: "55",
                label: 'Miejscowość podmiotu',
                name: 'entity_city',
                component: "InputText",
            }, {
                uuid: "56",
                label: 'Ulica podmiotu',
                name: 'entity_street',
                component: "InputText",
            }, {
                uuid: "57",
                label: 'Numer domu/budynku podmiotu',
                name: 'entity_house_number',
                component: "InputText",
            }, {
                uuid: "58",
                label: 'Numer lokalu/mieszkania podmiotu',
                name: 'entity_flat_number',
                component: "InputText",
            }, {
                uuid: "59",
                label: 'Kod pocztowy',
                name: 'entity_postal_code',
                component: "InputText",
            }, {
                uuid: "60",
                label: 'Waluta wierzytelności',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
            }, {
                uuid: "61",
                label: 'Wysokość wierzytelności',
                name: 'amount',
                component: "InputNumber",
            }, {
                uuid: "62",
                label: 'Termin zapłaty',
                name: 'payment_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
            }, {
                uuid: "63",
                label: 'Nazwa dokumentu potwierdzającego informację',
                name: 'document_name',
                component: "InputText",
            }, {
                uuid: "64",
                label: 'Data dokumentu',
                name: 'document_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
            }
        ]
    }, {
        name: 'Wierzyciele',
        subName: 'Składnik Wierzycieli',
        component: "SubObject",
        value: "5",
        subObjectIndex: 4,
        severityStateAdd: 'secondary',
        severityStateEdit: 'secondary',
        severityStateDel: 'secondary',
        content: [
            {
                uuid: "65",
                label: 'Imię i Nazwisko lub Nazwa wierzyciela',
                name: 'creditor_name',
                component: "InputText",
            }, {
                uuid: "66",
                label: 'Kraj wierzyciela',
                name: 'creditor_country',
                component: "InputText",
            }, {
                uuid: "67",
                label: 'Miejscowość wierzyciela',
                name: 'creditor_city',
                component: "InputText",
            }, {
                uuid: "68",
                label: 'Ulica wierzyciela',
                name: 'creditor_street',
                component: "InputText",
            }, {
                uuid: "69",
                label: 'Numer budynku wierzyciela',
                name: 'creditor_house_number',
                component: "InputText",
            }, {
                uuid: "70",
                label: 'Numer lokalu wierzyciela',
                name: 'creditor_flat_number',
                component: "InputText",
            }, {
                uuid: "71",
                label: 'Kod pocztowy',
                name: 'creditor_postal_code',
                component: "InputText",
            }, {
                uuid: "72",
                label: 'Waluta wierzytelności',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
            }, {
                uuid: "73",
                label: 'Kwota wierzytelności',
                name: 'amount',
                component: "InputNumber",
            }, {
                uuid: "74",
                label: 'Termin zapłaty',
                name: 'payment_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
            }
        ]
    }, {
        name: 'Sporne',
        subName: 'Składnik Sporne',
        component: "SubObject",
        value: "6",
        subObjectIndex: 5,
        severityStateAdd: 'secondary',
        severityStateEdit: 'secondary',
        severityStateDel: 'secondary',
        content: [
            {
                uuid: "75",
                label: 'Imię i Nazwisko lub Nazwa wierzyciela',
                name: 'creditor_name',
                component: "InputText",
            }, {
                uuid: "76",
                label: 'Kraj wierzyciela',
                name: 'creditor_country',
                component: "InputText",
            }, {
                uuid: "77",
                label: 'Miejscowość wierzyciela',
                name: 'creditor_city',
                component: "InputText",
            }, {
                uuid: "78",
                label: 'Ulica wierzyciela',
                name: 'creditor_street',
                component: "InputText",
            }, {
                uuid: "79",
                label: 'Numer budynku wierzyciela',
                name: 'creditor_house_number',
                component: "InputText",
            }, {
                uuid: "80",
                label: 'Numer lokalu wierzyciela',
                name: 'creditor_flat_number',
                component: "InputText",
            }, {
                uuid: "81",
                label: 'Kod pocztowy',
                name: 'creditor_postal_code',
                component: "InputText",
            }, {
                uuid: "82",
                label: 'Waluta wierzytelności',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
            }, {
                uuid: "83",
                label: 'Kwota wierzytelności',
                name: 'amount',
                component: "InputNumber",
            }, {
                uuid: "84",
                label: 'Termin zapłaty',
                name: 'payment_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
            }, {
                uuid: "85",
                label: 'Zakres kwestionowania',
                name: 'questioning_scope',
                component: "TextArea",
                innerInputProps: {
                    rows: 5,
                    cols: 50,
                    autoResize: true
                },
            }, {
                uuid: "86",
                label: 'Nazwa dokumentu',
                name: 'document_name',
                component: "InputText",
            }, {
                uuid: "87",
                label: 'Data dokumentu',
                name: 'document_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
            }
        ]
    }, {
        name: 'Przychody',
        subName: 'Składnik Przychodów',
        component: "SubObject",
        value: "7",
        subObjectIndex: 6,
        severityStateAdd: 'secondary',
        severityStateEdit: 'secondary',
        severityStateDel: 'secondary',
        content: [
            {
                uuid: "88",
                label: 'Rodzaj dochodu:',
                name: 'income_source',
                component: "Select",
                options: ["Wynagrodzenie - Umowa o pracę", "Wynagrodzenie - Umowa zlecenie", "Wynagrodzenie - Umowa o dzieło", "Świadczenie zdrowotne (L4)", "Świadczenie rehabilitacyjne", "Renta", "Emerytura", "Świadczenie pielęgnacyjne", "Dodatek pielęgnacyjny", "Świadczenie wychowawcze (800+)", "Pomoc społeczna MOPS", "Prace dorywcze", "Inne"]
            }, {
                uuid: "89",
                label: 'Waluta z ' + getDateMinus(1),
                name: 'currency1',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
            }, {
                uuid: "90",
                label: 'Przychód z ' + getDateMinus(1),
                name: 'income_amount1',
                component: "InputNumber",
            }, {
                uuid: "91",
                label: 'Czy kwota uwzględnia egzekucję komorniczą?',
                name: 'income_deduction1',
                component: "Select",
                options: ["Otrzymywana kwota po egzekucji komorniczej", "Otrzymywana kwota bez obciążeń komorniczych"],
            }, {
                uuid: "92",
                label: 'Waluta z ' + getDateMinus(2),
                name: 'currency2',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
            }, {
                uuid: "93",
                label: 'Przychód z ' + getDateMinus(2),
                name: 'income_amount2',
                component: "InputNumber",
            }, {
                uuid: "94",
                label: 'Czy kwota uwzględnia egzekucję komorniczą?',
                name: 'income_deduction2',
                component: "Select",
                options: ["Otrzymywana kwota po egzekucji komorniczej", "Otrzymywana kwota bez obciążeń komorniczych"],
            }, {
                uuid: "95",
                label: 'Waluta z ' + getDateMinus(3),
                name: 'currency3',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
            }, {
                uuid: "96",
                label: 'Przychód z ' + getDateMinus(3),
                name: 'income_amount3',
                component: "InputNumber",
            }, {
                uuid: "97",
                label: 'Czy kwota uwzględnia egzekucję komorniczą?',
                name: 'income_deduction3',
                component: "Select",
                options: ["Otrzymywana kwota po egzekucji komorniczej", "Otrzymywana kwota bez obciążeń komorniczych"],
            }, {
                uuid: "98",
                label: 'Waluta z ' + getDateMinus(4),
                name: 'currency4',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
            }, {
                uuid: "99",
                label: 'Przychód z ' + getDateMinus(4),
                name: 'income_amount4',
                component: "InputNumber",
            }, {
                uuid: "100",
                label: 'Czy kwota uwzględnia egzekucję komorniczą?',
                name: 'income_deduction4',
                component: "Select",
                options: ["Otrzymywana kwota po egzekucji komorniczej", "Otrzymywana kwota bez obciążeń komorniczych"],
            }, {
                uuid: "101",
                label: 'Waluta z ' + getDateMinus(5),
                name: 'currency5',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
            }, {
                uuid: "102",
                label: 'Przychód z ' + getDateMinus(5),
                name: 'income_amount5',
                component: "InputNumber",
            }, {
                uuid: "103",
                label: 'Czy kwota uwzględnia egzekucję komorniczą?',
                name: 'income_deduction5',
                component: "Select",
                options: ["Otrzymywana kwota po egzekucji komorniczej", "Otrzymywana kwota bez obciążeń komorniczych"],
            }, {
                uuid: "104",
                label: 'Waluta z ' + getDateMinus(6),
                name: 'currency6',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
            }, {
                uuid: "105",
                label: 'Przychód z ' + getDateMinus(6),
                name: 'income_amount6',
                component: "InputNumber",
            }, {
                uuid: "106",
                label: 'Czy kwota uwzględnia egzekucję komorniczą?',
                name: 'income_deduction6',
                component: "Select",
                options: ["Otrzymywana kwota po egzekucji komorniczej", "Otrzymywana kwota bez obciążeń komorniczych"],
            }
        ]
    }, {
        name: 'Wydatki',
        subName: 'Składnik wydatków',
        component: "SubObject",
        value: "8",
        subObjectIndex: 7,
        severityStateAdd: 'secondary',
        severityStateEdit: 'secondary',
        severityStateDel: 'secondary',
        content: [
            {
                uuid: "107",
                label: 'Rodzaj wydatku',
                name: 'spending_type',
                component: "Select",
                options: ["Czynsz i inne opłaty za mieszkanie/dom", "Opłaty za energię elektryczną", "Opłaty za gaz", "Opłaty za wodę i kanalizację (wywóz nieczystości płyn.)", "Opłaty za wywóz śmieci i innych odpadów", "Środki czystości i higieny osobistej", "Żywność i napoje bezalkoholowe", "Wydatki na zdrowie (wizyty lekarskie, leki, rehabilitacja)", "Opłaty za telewizję ", "Opłaty za Internet ", "Opłaty za telefon ", "Odzież i obuwie ", "Wydatki na transport i przejazdy", "Pozostałe wydatki"]
            }, {
                uuid: "108",
                label: 'Opis wydatku (tylko w przy "Pozostałe wydatki")',
                name: 'spending_name',
                component: "InputText",
            }, {
                uuid: "109",
                label: 'Waluta wydatku',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
            }, {
                uuid: "110",
                label: "Miesięczna kwota przeznaczana na ten cel:",
                name: 'amount',
                component: "InputNumber",
            }
        ]
    }, {
        name: 'Obrót n. a. s.',
        subName: 'Składnik n. a. s.',
        component: "SubObject",
        value: "9",
        subObjectIndex: 8,
        severityStateAdd: 'secondary',
        severityStateEdit: 'secondary',
        severityStateDel: 'secondary',
        content: [
            {
                uuid: "111",
                label: "Przedmiot czynności prawnej:",
                name: "legal_action_name",
                component: "Select",
                options: ["Nieruchomość", "Akcje", "Udziały w spółkach"],
            }, {
                uuid: "112",
                label: "Rodzaj czynności prawnej:",
                name: "legal_action_type",
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["Sprzedaż", "Darowizna", "Cesja", "Zakup"],
            }, {
                uuid: "113",
                label: "Uwagi do czynności prawnej:",
                name: 'legal_action_additional_info',
                component: "TextArea",
                placeholder: 'Pole opcjonalne',
                innerInputProps: {
                    rows: 5,
                    cols: 50,
                    autoResize: true
                },
            }
        ]
    }, {
        name: 'Obrót powyżej 10 000zł',
        subName: 'Składnik obrotu powyżej 10 000zł',
        component: "SubObject",
        value: "10",
        subObjectIndex: 9,
        severityStateAdd: 'secondary',
        severityStateEdit: 'secondary',
        severityStateDel: 'secondary',
        content: [
            {
                uuid: "114",
                label: "Przedmiot czynności prawnej:",
                name: "legal_action_name",
                component: "InputText",
            }, {
                uuid: "115",
                label: "Rodzaj czynności prawnej:",
                name: "legal_action_type",
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["Sprzedaż", "Zakup", "Darowizna", "Cesja"],
            }, {
                uuid: "116",
                label: "Uwagi do czynności prawnej:",
                name: 'legal_action_additional_info',
                component: "TextArea",
                placeholder: 'Pole opcjonalne',
                innerInputProps: {
                    rows: 5,
                    cols: 50,
                    autoResize: true
                },
            }
        ]
    }, {
        name: 'Podani Komornicy',
        subName: 'Komornik',
        component: "SubObject",
        value: "11",
        subObjectIndex: 3,
        severityStateAdd: 'secondary',
        severityStateEdit: 'secondary',
        severityStateDel: 'secondary',
        content: [
            {
                uuid: "117",
                label: 'Imię komornika',
                name: 'inspector_name',
                component: "InputText",
            }, {
                uuid: "118",
                label: 'Nazwisko komornika',
                name: 'inspector_surname',
                component: "InputText",
            }, {
                uuid: "119",
                label: 'Miejscowość siedziby komornika',
                saveName: 'inspector_city',
                name: 'city',
                component: "InputText",
            }, {
                uuid: "120",
                label: 'Ulica siedziby komornika',
                saveName: 'inspector_street',
                name: 'street',
                component: "InputText",
            }, {
                uuid: "121",
                label: 'Numer budynku siedziby komornika',
                saveName: 'inspector_house_number',
                name: 'house_number',
                component: "InputText",
            }, {
                uuid: "122",
                label: 'Numer lokalu siedziby komornika',
                saveName: 'inspector_flat_number',
                name: 'flat_number',
                component: "InputText",
            }, {
                uuid: "123",
                label: 'Kod pocztowy',
                saveName: 'inspector_postal_code',
                name: 'postal_code',
                component: "InputText",
            }
        ]
    }, {
        name: 'Firmy windykacyjne',
        subName: 'Firma windykacyjna',
        component: "SubObject",
        value: "12",
        subObjectIndex: 2,
        severityStateAdd: 'secondary',
        severityStateEdit: 'secondary',
        severityStateDel: 'secondary',
        content: [
            {
                uuid: "124",
                label: 'Wybierz firmę windykacyjną, która się z Tobą kontaktowała',
                name: 'windycation_company_name_select',
                component: "Select",
                options: ["Best", "Kruk/Prokura", "Ultimo", "Koksztys", "Eos", "Hoist", "Pra Group", "Raport/Axfina", "Kredyt Inkaso", "Intrum Justitia", "Asekuracja", "Aasa Polska", "Presco Investments", 'Debito NSFIZ', "Inna Firma"],
            }, {
                uuid: "125",
                label: 'Nazwa firmy windykacyjnej',
                name: 'windycation_company_name',
                component: "InputText",
            }, {
                uuid: "126",
                label: 'Miejscowość siedziby firmy windykacyjnej',
                saveName: 'windycation_company_city',
                name: 'city',
                component: "InputText",
            }, {
                uuid: "127",
                label: 'Ulica siedziby firmy windykacyjnej',
                saveName: 'windycation_company_street',
                name: 'street',
                component: "InputText",
            }, {
                uuid: "128",
                label: 'Numer budynku siedziby firmy windykacyjnej',
                saveName: 'windycation_company_house_number',
                name: 'house_number',
                component: "InputText",
            }, {
                uuid: "129",
                label: 'Numer lokalu siedziby firmy windykacyjnej',
                saveName: 'windycation_company_flat_number',
                name: 'flat_number',
                component: "InputText",
            }, {
                uuid: "130",
                label: 'Kod pocztowy',
                saveName: 'windycation_company_postal_code',
                name: 'postal_code',
                component: "InputText",
            }
        ]
    }, {
        name: 'Dokumenty od komorników i firm windykacyjnych',
        subName: 'Dokument',
        component: "SubObject",
        value: "13",
        subObjectIndex: 11,
        severityStateAdd: 'secondary',
        severityStateEdit: 'secondary',
        severityStateDel: 'secondary',
        content: [
            {
                uuid: "131",
                label: 'Nazwa dokumentu',
                name: 'document_name',
                component: "InputText",
            }, {
                uuid: "132",
                label: 'Data dokumentu',
                name: 'document_date',
                component: "InputText",
            }
        ]
    }, {
        name: 'Pozostałe dokumenty',
        subName: 'Dokument',
        component: "SubObject",
        value: "14",
        subObjectIndex: 10,
        severityStateAdd: 'secondary',
        severityStateEdit: 'secondary',
        severityStateDel: 'secondary',
        content: [
            {
                uuid: "133",
                label: 'Nazwa dokumentu',
                name: 'document_name',
                component: "InputText",
            }, {
                uuid: "134",
                label: 'Data dokumentu',
                name: 'document_date',
                component: "InputText",
            }, {
                uuid: "135",
                label: 'Fakt podlegający stwierdzeniu',
                name: 'fact',
                component: "InputText",
            }
        ]
    }
]



);

const subObjects = ref(formData.value.filter((tab, tabIndex) => tab.component === 'SubObject'));
// console.log("🚀 ~ subObjects:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", subObjects)

subObjects.value.forEach((tab, tabIndex) => {
    let columns = [];

    let adresses = tab.content.filter((item, itemIndex) => {
        return [item.name].some(key => key.endsWith('_country') || key.endsWith('_city') || key.endsWith('_street') || key.endsWith('_postal_code') || key.endsWith('_house_number') || key.endsWith('_flat_number'));
    })

    let generalKey = adresses.find((item, itemIndex) => item.name.endsWith('_city'))?.name.replace('_city', '');

    if (generalKey) {
        columns.push({
            colLabel: 'Adres',
            name: generalKey + '_address',
        });
    }

    tab.content.map((item, itemIndex) => {
        const hasLocationKeys = [item.name].some(key => key.endsWith('_country') || key.endsWith('_city') || key.endsWith('_street') || key.endsWith('_postal_code') || key.endsWith('_house_number') || key.endsWith('_flat_number'));
        if (!hasLocationKeys) {
            columns.push({
                colLabel: item.label,
                name: item.name,
            });
        }
    })

    tab.columns = columns;


});

formValues.value = formData.value.map((tab, tabIndex) =>
    tab.content.reduce((acc, item, itemIndex) => {
        acc[itemIndex] = { uuid: item.uuid, value: null };
        return acc;
    }, {}))
console.log("🚀 ~ formValues:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", formValues.value)


watch(value, (newVal) => {
    activeSubObject.value = subObjects.value.find(obj => obj.value === newVal)?.subObjectIndex || 0;
});

let add = false;

const saveSubObject = (subObjectIndex) => {
    // console.log("🚀 ~ saveSubObject ~ generalKey:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", Object.keys(activeObject.value))
    let generalKey = Object.keys(activeObject.value).find(key => key.endsWith('_city'))?.replace('_city', '');
    // console.log("🚀 ~ saveSubObject ~ generalKey:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", generalKey)

    if (generalKey !== undefined) {
        activeObject.value[generalKey + '_address'] =
            activeObject.value[Object.keys(activeObject.value).find(key => key.endsWith('_street'))] + " " +
            activeObject.value[Object.keys(activeObject.value).find(key => key.endsWith('_house_number'))] +
            (activeObject.value[Object.keys(activeObject.value).find(key => key.endsWith('_flat_number'))] === undefined ? "" : "/" + activeObject.value[Object.keys(activeObject.value).find(key => key.endsWith('_flat_number'))]) + ", " +
            activeObject.value[Object.keys(activeObject.value).find(key => key.endsWith('_postal_code'))] + " " +
            activeObject.value[Object.keys(activeObject.value).find(key => key.endsWith('_city'))] + ", " +
            activeObject.value[Object.keys(activeObject.value).find(key => key.endsWith('_country'))];
    }


    if (add) {
        subObjectValues.value[subObjectIndex].push(activeObject.value);
        showToast("success", "Dodano nowy obiekt", "Pomyślnie dodano składnik majątku.");
        add = false;
    } else {
        showToast("success", "Edytowano obiekt", "Pomyślnie edytowano składnik majątku.");
    }
    activeObject.value = null;
    showSubObject();
}

const addSubObject = (subObjectIndex) => {
    add = true;

    const obj = ref(formData.value.filter((tab, tabIndex) => tab.component === 'SubObject'));
    // console.log("🚀 ~ addSubObject ~ obj:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", obj.value)
    obj.value = obj.value.find(o => o.subObjectIndex === subObjectIndex).content.reduce((acc, item, itemIndex) => {

        acc[item.name] = null;
        return acc;
    }, {})
    obj.value.uuid = tempUUID;
    tempUUID++;
    activeObject.value = obj.value
    showSubObject();

    // console.log("🚀 ~ addSubObject ~ activeObjectIndex:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", activeObject.value)
    // console.log("🚀 ~ subObjects:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", subObjects)
    // console.log("🚀 ~ addSubObject ~ subObjectValues:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", subObjectValues.value)
}

const editSubObject = (subObjectIndex) => {
    if (activeObject.value == null) {
        showToast("warn", "Nie wybrano obiektu", "Wybierz obiekt do edycji.");
        return;
    }


    // console.log("🚀 ~ addSubObject ~ activeObject:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", activeObject.value)
    showSubObject();
}


const deleteSubObject = (subObjectIndex) => {
    if (activeObject.value == null) {
        showToast("warn", "Nie wybrano obiektu", "Wybierz obiekt do usunięcia.");
        return;
    }
    // console.log("🚀 ~ deleteSubObject ~ activeObject.value:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", activeObject.value)
    // console.log("🚀 ~ deleteSubObject before ~ subObjectValues:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", subObjectValues.value)
    const indexToRemove = subObjectValues.value[subObjectIndex].findIndex(obj => obj.uuid === activeObject.value.uuid);
    if (indexToRemove > -1) {
        subObjectValues.value[subObjectIndex].splice(indexToRemove, 1);
    }
    // console.log("🚀 ~ deleteSubObject after ~ subObjectValues:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", subObjectValues.value)
    activeObject.value = null;
    showToast("success", "Usunięto obiekt", "Pomyślnie usunięto składnik majątku.");
}

const cancelSubObject = () => {
    activeObject.value = null;
    showSubObject();
}


const saveForm = async () => {

    for (let i = 0; i < 18; i++) {
        loadedData.data.formVal[i + 1] = formValues.value[2][i].value;
    }
    for (let i = 0; i < 8; i++) {
        loadedData.data.formVal[i + 262] = formValues.value[0][i + 1].value;
    }
    for (let i = 0; i < 3; i++) {
        loadedData.data.formVal[i + 275] = formValues.value[0][i + 9].value;
    }
    loadedData.data.formVal[279] = formValues.value[0][12].value;
    loadedData.data.formVal[281] = formValues.value[0][13].value;
    loadedData.data.formVal[282] = formValues.value[0][14].value;

    if (formValues.value[1][1].value != 'matched' && loadedData.statusCourt == 'not_matched' && formValues.value[1][0].value == 'ready') {
        showToast("warn", "Niepoprawny status sądu", "System nie był w stanie automatycznie dopasować sądu.");
        return;
    }

    // if (formValues.value[1][0].value != 'ready') {
    //     showToast("warn", "Zapisano, ze starym statusem", "Wniosek dalej jest w trybie weryfikacji przez administratora - kiedy wniosek będzie gotowy do oddanie klientowi zmień status na 'zweryfikowany'.", 6000);
    // }

    const response = await fetch('/api/objectModify', {
        body: JSON.stringify({
            object: {
                root: {
                    labels: [
                        "SimpleForm"
                    ],
                    properties: {
                        name: formValues.value[2][0].value || "",
                        surname: formValues.value[2][1].value || "",
                        phone: formValues.value[2][4].value || "",
                        pesel: formValues.value[2][2].value || "",
                        email: formValues.value[2][3].value || "",
                        data: JSON.stringify(loadedData.data) || "",
                        objects: JSON.stringify(subObjectValues.value) || "",
                        description: formValues.value[0][0].value || "",
                        status: formValues.value[1][0].value,
                        statusCourt: formValues.value[1][1].value
                    },
                    uuid: uuid.value,
                    list_prop: {
                        dataType: "Map",
                        value: []
                    },
                    orgUUID: "0a754dfd-5c83-4356-baff-1b687aaf3fd1",
                    mod_parent: 0,
                    mod_order: 0,
                    mod_org: 0,
                    mod: 2
                },
                formUUID: "169fe196-b2de-4d7d-8ebe-d1217251717a",
                objects: {
                    dataType: "Map",
                    value: []
                },
                structure: {
                    dataType: "Map",
                    value: []
                },
                formEditDate: 1910959199504
            }
        })
    });

    if (response.ok) {
        const data = await response.json();

        if (data.success == 1) {
            if (formValues.value[1][0].value != 'ready') {
                showToast("warn", "Zapisano, ze starym statusem", "Wniosek dalej jest w trybie weryfikacji przez administratora - kiedy wniosek będzie gotowy do oddanie klientowi zmień status na 'zweryfikowany'.", 6000);
            }
            else {
                showToast('success', 'Sukces', 'Pomyślnie zapisano wniosek.');
                router.push('/search');
            }

        }
        else {
            showToast('error', 'Błąd', data.result);
        }
    }
}



</script>
