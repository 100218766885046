<template>
    <span class="m-3 mb-1 text-lg">{{ label }}</span>
</template>

<script>
export default {
  props: {
    label: String
  }
}
</script>
