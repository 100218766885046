<template>
  <div
    class="w-screen h-screen relative flex items-center justify-center bg-gray-100 dark:bg-gray-700"
  >
    <div class="max-w-xl mx-auto sm:px-6 lg:px-8">
      <div class="flex items-center pt-8 sm:justify-start sm:pt-0">
        <div
          class="px-4 text-2xl text-gray-700 border-r border-gray-700 tracking-wider dark:border-gray-100 dark:text-gray-100"
        >
          404
        </div>
        <div class="ml-4 text-2xl text-gray-700 uppercase tracking-wider dark:text-gray-100">
          Not Found
        </div>
      </div>
    </div>
  </div>
</template>
