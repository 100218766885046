<template>
    <div class="w-screen h-screen relative flex items-center justify-center bg-gray-100 dark:bg-gray-700">
        <div class="max-w-xl mx-auto sm:px-6 lg:px-8">
            <div class="flex items-center pt-8 sm:justify-start sm:pt-0">
                <div class="ml-4 text-4xl text-gray-700 tracking-wider dark:text-gray-100">
                    Przeglądarki mobilne nie są wspierane w tej wersji systemu Invictus 
                    <br><br>
                    Prosimy skorzystać z przeglądarki na komputerze.
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>