<template>
    <Tag :value="getMsg(status)" :severity="getSeverity(status)" v-bind="innerInputProps"/>
</template>

<script setup>
import Tag from 'primevue/tag';
</script>

<script>
export default {
    props: {
        status: {
            type: String,
            default: ''
        },
        severityMap: {
            type: Map,
            default: () => new Map()
        },
        msgMap: {
            type: Map,
            default: () => new Map()
        },
        innerInputProps: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        getSeverity(status) {
            if(!this.severityMap.has(status)) return "secondary";
            return this.severityMap.get(status);
        },
        getMsg(status) {
            if(!this.msgMap.has(status)) return null;
            return this.msgMap.get(status);
        }
    }
}
</script>
