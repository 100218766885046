<template>
    <main class="mt-0 pl-3 pr-2 pb-3 flex flex-col justify-start items-center gap-3">
        <Card class="w-full xl:w-11/12" :pt="{ body: { class: 'p-3' }, content: { class: 'p-0' } }">
            <template #content>
                <DataTable v-model:filters="filters" v-model:selection="selectedObject" :value="wnioski" showGridlines
                    size="small" class="bg-white" filterDisplay="menu" :loading="loading" scrollable scrollHeight="550px" :virtualScrollerOptions="{ itemSize: 7 }"
                    :globalFilterFields="['name', 'surname', 'pesel', 'phone', 'status']">
                    <template #header>
                        <div class="flex justify-between">

                            <Button type="button" icon="pi pi-filter-slash" label="Wyczyść" outlined
                                @click="clearFilter()" />
                            <div class="flex justify-end w-full">
                                <IconField>
                                    <InputIcon>
                                        <i class="pi pi-search" />
                                    </InputIcon>
                                    <InputText v-model="filters['global'].value" placeholder="Wyszukaj" />
                                </IconField>
                            </div>
                        </div>

                    </template>
                    <template #empty> Brak wniosków. </template>
                    <template #loading> Ładowanie danych. Proszę czekać. </template>
                    <Column class="w-[5%] min-w-12 max-w-20" bodyStyle="text-align:center">
                        <template #body="slotProps">
                            <i class="pi pi-pencil cursor-pointer" @click="editObject(slotProps.data.uuid)" />
                        </template>
                    </Column>
                    <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="w-full"
                                placeholder="Wyszukaj" />
                        </template>
                    </Column>
                    <!-- <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header"
                        class="text-wrap"></Column> -->
                    <Column key="status" field="status" header="Status" :showFilterMatchModes="false">
                        <template #body="slotProps">
                            <StatusTag :status="slotProps.data.status"
                                :severityMap="severityMap"  :msgMap="msgMap"/>
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <Select v-model="filterModel.value" @change="filterCallback()" :options="statuses"
                                placeholder="Wybierz status" style="min-width: 12rem" :showClear="false">
                                <template #value="slotProps">
                                    <StatusTag v-if="slotProps.value !== null" :status="slotProps.value" :severityMap="severityMap"  :msgMap="msgMap" />
                                </template>
                                <template #option="slotProps">
                                    <StatusTag :status="slotProps.option" :severityMap="severityMap"  :msgMap="msgMap" />
                                </template>
                            </Select>
                        </template>
                    </Column>
                    <Column key="statusCourt" field="statusCourt" header="Status sądu" :showFilterMatchModes="false">
                        <template #body="slotProps">

                            <StatusTag :status="slotProps.data.statusCourt"
                            :severityMap="severityMap"  :msgMap="msgMap" />
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <Select v-model="filterModel.value" @change="filterCallback()" :options="statusesCourt"
                                placeholder="Wybierz status" style="min-width: 12rem" :showClear="false">
                                <template #value="slotProps">
                                    <StatusTag v-if="slotProps.value !== null" :status="slotProps.value"
                                    :severityMap="severityMap"  :msgMap="msgMap" />
                                </template>
                                <template #option="slotProps">
                                    <StatusTag :status="slotProps.option" :severityMap="severityMap"  :msgMap="msgMap" />
                                </template>
                            </Select>
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>

    </main>
</template>

<script setup>
import { ref, onMounted, onUnmounted, inject } from 'vue'
import { FilterMatchMode, FilterOperator } from '@primevue/core/api';
import { useRouter } from 'vue-router'

import Card from 'primevue/card';
import Tag from 'primevue/tag';
import StatusTag from '@/components/forms/StatusTag.vue';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import InputText from 'primevue/inputtext';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';

import Select from 'primevue/select';
import Button from 'primevue/button';

const router = useRouter();
const showToast = inject('showToast');

const wnioski = ref();
const loading = ref(true);
const selectedObject = ref();
const filters = ref();
let audio;

const statuses = ref(['ready', 'verification_needed', 'requires_completion','transaction_in_progress','no_access']);
const statusesCourt = ref(['never_matched', 'matched', 'not_matched']);

const severityMap = new Map([
    ['no_access', 'danger'],
    ['not_matched', 'danger'],
    ['transaction_in_progress', 'secondary'],
    ['requires_completion', 'secondary'],
    ['never_matched', 'secondary'],
    ['verification_needed', 'warn'],
    ['ready', 'success'],
    ['matched', 'success'],
]);

const msgMap = new Map([
    ['no_access', 'Nie opłacony'],
    ['not_matched', 'Nie dopasowano'],
    ['transaction_in_progress', 'Transakcja w toku'],
    ['requires_completion', 'Wymaga uzupełnienia'],
    ['never_matched', 'Nie wyszukiwano'],
    ['verification_needed', 'Wymaga weryfikacji'],
    ['ready', 'Zweryfikowany'],
    ['matched', 'Dopasowano'],
]);

const initFilters = () => {
    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        surname: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        pesel: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        statusCourt: { value: null, matchMode: FilterMatchMode.EQUALS },
    }
}
initFilters();
filters.value.status.value = 'verification_needed';

function clearFilter() {
    initFilters();
}

async function getObjects() {
    const response = await fetch('/api/getListObjects', {
        body: JSON.stringify({
            listUUID: "f6f22d5b-ec4a-4ba9-b2ce-8a3d84a1f933",
            propertyMap: {
                name: ".*",
                surname: ".*",
                email: ".*",
                pesel: ".*",
                phone: ".*",
                uuid: ".*",
                statusCourt: ".*",
                maxActive: ".*",
                status: ".*"
            }
        }),
    });

    if (response.ok) {
        const data = await response.json();

        if (data.success == 1) {
            setTimeout(() => {
                wnioski.value = data.result.objects;
                console.log("🚀 ~ setTimeout ~ wnioski.value:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", wnioski.value)
                loading.value = false;
                let verf_ned = data.result.objects.find(x => x.status == 'verification_needed')
                if(verf_ned)audio.play();
            })
        }
        else if (data.success == 2) {
            showToast('error', 'Błąd', data.result);
        }
    } else {
        showToast('error', 'Błąd', 'Problem z połączeniem z serwerem');
    }
}

onMounted(() => {
    audio = new Audio('notification.wav');
    audio.volume = 0.4;
    getObjects();
})

let myInterval = setInterval(getObjects, 20000);

onUnmounted(() => {
    clearInterval(myInterval);
})

const columns = [
    { field: 'name', header: 'Imie' },
    { field: 'surname', header: 'Nazwisko' },
    { field: 'email', header: 'Email' },
    { field: 'pesel', header: 'PESEL' },
    { field: 'phone', header: 'Nr telefonu' },
    // { field: 'status', header: 'Status' }
];

const editObject = (uuid) => {
    router.push(`wniosekAdmin/${uuid}`);
}
</script>
