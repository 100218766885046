<template>
    <main>
        <form id="payment-form" @submit.prevent="handleSubmit">
            <div id="link-authentication-element" />
            <div id="payment-element" />
            <div class="w-full flex justify-center items-center mt-4">
                <Button class="w-fit" id="submit" :disabled="isLoading" @click="handleSubmit">
                    Zapłać
                </Button>
            </div>
        </form>
    </main>
</template>

<script setup>
import Button from 'primevue/button';
import { ref, onMounted } from 'vue';
import { loadStripe } from '@stripe/stripe-js/pure';
import { userdataStore } from '@/stores/userData';

const emit = defineEmits(['payment-status', 'close-window']);
const userdata = userdataStore();
const isLoading = ref(true);

const stripe = ref(null);
const clientSecret = ref(null);
const elements = ref(null);

onMounted(async () => {
    let obj = await fetch('/api/create-payment-intent').then((r) => r.json());
    
    const publishableKey = obj.publishableKey;
    const clientSecretData = obj.clientSecret;

    clientSecret.value = clientSecretData;

    loadStripe.setLoadParameters({advancedFraudSignals: false});
    stripe.value = await loadStripe( publishableKey, {
        wallets: { googlePay: 'never' },
        advancedFraudSignals: false,
        locale: 'pl'
    });

    try {
        elements.value = stripe.value.elements({
            clientSecret: clientSecret.value,
        });
        const paymentElement = elements.value.create('payment', {
            fields: {
                billingDetails: {
                    email: 'never'
                }
            },
            wallets: { googlePay: 'never' }
        });
        paymentElement.on('ready', () => {
            isLoading.value = false;
        });
        paymentElement.mount('#payment-element');
    } catch (error) {
        console.log("->", error);
    }
});

const handleSubmit = async () => {
    const PaymentIntent = await stripe.value.confirmPayment({
        elements: elements.value,
        confirmParams: {
            payment_method_data:
            {
                billing_details:
                    { email: userdata.email },
            }
        },
        redirect: "if_required"
    });

    if (PaymentIntent.paymentIntent.lastPaymentError) {
        emit('payment-status', { status: '2', error: PaymentIntent.paymentIntent.lastPaymentError });
    } else if (PaymentIntent.paymentIntent.status === 'succeeded') {
        emit('payment-status', { status: '1' });
    }
    emit('close-window');
}
</script>
