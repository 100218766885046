<template>
    <span class="m-1 mb-1 text-sm text-red-600">{{ labels[index] }}</span>
</template>

<script>
export default {
  props: {
    labels: {
        type: Array,
        default: () => [] 
    },
    index: {
        type: Number,
        default: 0 
    }
  }
}
</script>
